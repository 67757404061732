/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	var MV = {
		// All pages
		'common': {
			init: function() {
				// Inject SVG's
				var svgsToInject = document.querySelectorAll('img.svg'),
					svgInjector = new SVGInjector(svgsToInject);

				// Team member toggle
				$('.teammember').on('click', '.teammember__toggle', function(e) {
					var $el = $(e.delegateTarget);

					$el.toggleClass('active');

					e.preventDefault();
				});

				// Menu submenu
				// $('.header-site .menu-item-has-children > a').on('click', function(e) {
				// 	$(this).parents('li').toggleClass('active');

				// 	e.preventDefault();
				// });

				$('.header-site .menu-item-has-children').hoverIntent({
					'over': function() {
						$(this).addClass('active');
					},
					'out': function() {
						$(this).removeClass('active');
					},
					'timeout': 300
				});


				// Accordeon heading toggle
				$('.panel-heading a').on('click', function() {
					$(this).toggleClass('active');
				});

				// Sharing
				$('.share').click(function(e){
					var sTop = window.screen.height/2-(218);
					var sLeft = window.screen.width/2-(313);
					var win = window.open(this.href,'sharer','toolbar=0,status=0,resizable=0,location=0,width=626,height=256,top='+sTop+',left='+sLeft);
					e.preventDefault();
				});
			},
			finalize: function() {
			
			}
		}
	};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = MV;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
